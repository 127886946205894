import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function YesNo(props) {

    const [ yesNoValue, setYesNoValue ] = useState('-');
    const settingsUpdate = useContext(OnlineFormDispatchContext);

    if ( typeof(props.question) === 'undefined' ) return;

    const handleChange = (e) => { 
        setYesNoValue( e.target.value );
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    return ( 
    <Box className="workingfeedback-question-block" data-question-type="YES_NO" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1={props.question.flag_1} data-flag2={props.question.flag_2} data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text }</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Select size="small" sx={{ width: '100%' }} value={yesNoValue} defaultValue={props.question.default_value} onChange={handleChange}>
                    <MenuItem value="-">Please select</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </Select>
            </Grid>
        </Grid>
    </Box>
    )
}
