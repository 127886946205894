import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from './utils/axios';

import { OnlineFormContext, OnlineFormDispatchContext } from './contexts/OnlineFormContextProvider';

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import StaticTitle from './components/questions/StaticTitle';
import StaticBody from './components/questions/StaticBody';
import StaticImprovements from './components/questions/StaticImprovements';
import StaticContact from './components/questions/StaticContact';
import StaticText from './components/questions/StaticText';
import Divider from './components/questions/Divider';
import Dropdown from './components/questions/Dropdown';
import YesNo from './components/questions/YesNo';
import Freetext from './components/questions/Freetext';
import MultipleChoice from './components/questions/MultipleChoice';
import TreatmentType from './components/questions/TreatmentType';
import StaffFilter from './components/questions/StaffFilter';
import FFT from './components/questions/FFT';
import NPS from './components/questions/NPS';
import CrossSellServices from './components/questions/CrossSellServices';
import SubmitFormSection from './components/questions/SubmitFormSection';
import StarRating from './components/questions/StarRating';

import { AnimatedGreenTick } from './components/EmbeddedImages';
import './styles/online-form.css';

export default function OnlineForm() {

    const params = useParams();
    const navigate = useNavigate();
    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ company, setCompany ] = useState(''); 
    const [ questionSet, setQuestionSet ] = useState('');
    const settings = useContext(OnlineFormContext);
    const settingsUpdate = useContext(OnlineFormDispatchContext);

    // Load in the company data and question set
    useEffect( () => {         
        // Ensure that the company ID is numeric
        if (! /^[0-9]+$/.test(params.companyID)) {
            navigate('/render/invalid');            
        }

        // Get the company details and question set
        axios.get(`/company/${params.companyID}/question-set`)
        .then((response) => { 
            setCompany( response.data?.company?.name );
            setQuestionSet( response.data?.questionSet?.questions );
            settingsUpdate({ type: 'set_company', company: response.data?.company });
            settingsUpdate({ type: 'set_questions', questionSet: response.data?.questionSet?.questions });

            if ( response.data?.company?.healthcareType === 'Mixed' || response.data?.company?.healthcareType === 'Unknown' ) { 
                settingsUpdate({ type: 'set_treatment_type', treatmentType: '-' });
                settingsUpdate({ type: 'set_hide_treatment_type_question', hideTreatmentTypeQuestion: false });
            } else { 
                settingsUpdate({ type: 'set_treatment_type', treatmentType: response.data?.company?.healthcareType });
            }
            
            setLoading(false);
        })
        .catch(() => { 
            setError(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: Remove this block when we no longer need to debug in the console
    useEffect( () => { 
        // console.log(settings);
    }, [settings]);


    // On error, navigate away to dedicated component
    useEffect( () => { 
        if ( error === true ) { 
            navigate('/render/error');            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])


    return ( 
        <Box style={{ margin: '20px' }}>
            { loading && <LinearProgress mb={3} color="primaryGreen" /> }

            { !loading && settings.complete && ( 
                <Card sx={{ maxWidth: '1000px' }}>
                    <CardContent style={{ textAlign: 'center' }}>
                        <img src={AnimatedGreenTick} alt="Green tick showing success" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        <Typography variant="h5" className="workingfeedback-h5" mb={3}>
                            Thanks for leaving feedback for {company}
                        </Typography>
                        <Typography variant="body2" className="workingfeedback-text" mb={3}>
                            { settings?.medium === 'online' && ( 
                                <>A verification email has been sent to {settings?.authorEmail} containing a link that you must follow to verify your 
                                review and complete your feedback submission.</>
                            )}
                            { settings?.medium !== 'online' && ( 
                                <>Your feedback has been submitted successfully.</>
                            )}
                        </Typography>
                    </CardContent>
                </Card>
            )}

            { !loading && !settings?.complete && ( 
                <Card sx={{ maxWidth: '1000px' }}>
                    <CardContent>
                        <Typography variant="h5" className="workingfeedback-h5" sx={{ marginBottom: '3px' }}>
                            Leave your opinion on {company}
                        </Typography>
                        <Typography variant="body2" className="workingfeedback-text" mb={3}>
                            Your comments and feedback are always of great value to {company}, so please let them know your thoughts. You can choose to remain anonymous if you wish.
                        </Typography>

                        <Stack direction="column" spacing={3}>
                        { questionSet.map( (question, i) => { 
                            if ( question.type === 'STATIC_TITLE' ) return ( <StaticTitle key={i} question={question} /> )
                            if ( question.type === 'STATIC_BODY' ) return ( <StaticBody key={i} question={question} /> )
                            if ( question.type === 'STATIC_IMPROVEMENTS' ) return ( <StaticImprovements key={i} question={question} /> )
                            if ( question.type === 'STATIC_CONTACT' ) return ( <StaticContact key={i} question={question} /> )
                            if ( question.type === 'STATIC_TEXT' ) return ( <StaticText key={i} question={question} /> )
                            if ( question.type === 'DIVIDER' ) return ( <Divider key={i} /> )
                            if ( question.type === 'DROP_DOWN' ) return ( <Dropdown key={i} question={question} /> )
                            if ( question.type === 'YES_NO' ) return ( <YesNo key={i} question={question} /> )
                            if ( question.type === 'FREETEXT' ) return ( <Freetext key={i} question={question} /> )
                            if ( question.type === 'MULTIPLE_CHOICE' ) return ( <MultipleChoice key={i} question={question} /> )
                            if ( question.type === 'STAR_RATING' ) return ( <StarRating key={i} question={question} /> )
                            if ( question.type === 'NPS_RATING' ) return ( <NPS key={i} question={question} /> )
                            if ( question.type === 'TREATMENT_TYPE' ) return ( <TreatmentType key={i} question={question} /> )
                            if ( question.type === 'STAFF_FILTER' ) return ( <StaffFilter key={i} question={question} /> )
                            if ( question.type === 'FFT_QUESTION' ) return ( <FFT key={i} question={question} /> )
                            if ( question.type === 'CROSS_SELL_SERVICES' ) return ( <CrossSellServices key={i} question={question} /> )
                            return ( <></> );
                            // DEBUG ONLY FOR MISSING QUESTION TYPES: return ( <p key={i}>{ question?.type }</p> );
                        }) }
                        <SubmitFormSection />
                        </Stack>

                        
                    </CardContent>
                </Card>
            )}            
        </Box> 
    )
    
}