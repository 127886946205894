import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function MultipleChoice(props) {

  const settingsUpdate = useContext(OnlineFormDispatchContext);
  const [ answers, setAnswers ] = useState([]);
  
  const handleChange = (e) => { 
    let newValues = [];
    if ( e.target.checked === false ) { 
        newValues = answers.filter(function(val) { return val !== e.target.value });        
    } else { 
        newValues = [...answers, e.target.value ];
    }
    setAnswers(newValues);
    settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: newValues });
    console.log(newValues);
  }

  return ( 
    <Box className="workingfeedback-question-block" data-question-type="FREETEXT" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1={props.question.flag_1} data-flag2={props.question.flag_2} data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text }</Typography>
            </Grid>
        </Grid>
        { props.question.answers.length > 0 && ( 
            <Grid container>                
                { props.question.answers?.map( (answer, i) =>  
                    <Grid item xs={12} md={6} key={i}>
                        <FormControlLabel sx={{ width: '100%' }} control={<Checkbox value={answer.text} onChange={(e) => handleChange(e) } />} label={answer.text} />
                    </Grid>
                ) }                
            </Grid>
        )}
                    
        
    </Box>
  )
}
