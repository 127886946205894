import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function FFT(props) {

    const [ fftValue, setFFTValue ] = useState('-');
    const settingsUpdate = useContext(OnlineFormDispatchContext);
    
    if ( typeof(props.question) === 'undefined' ) return;
    
    const handleChange = (e) => { 
        setFFTValue( e.target.value );
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    return ( 
    <Box className="workingfeedback-question-block" data-question-type="FFT_QUESTION" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1={props.question.flag_1} data-flag2={props.question.flag_2} data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography className="workingfeedback-question-smalltitle" variant="body2" component="div">
                    Thinking about your recent visit...
                </Typography>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">
                    Overall, how was your experience of our service?
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Select size="small" sx={{ width: '100%' }} value={fftValue} defaultValue={props.question.default_value} onChange={(e) => handleChange(e)}>
                    <MenuItem value="-">Please select</MenuItem>
                    <MenuItem value="Very good">Very good</MenuItem>
                    <MenuItem value="Good">Good</MenuItem>
                    <MenuItem value="Neither good nor poor">Neither good nor poor</MenuItem>
                    <MenuItem value="Poor">Poor</MenuItem>
                    <MenuItem value="Very poor">Very poor</MenuItem>
                    <MenuItem value="Don't know">Don't know</MenuItem>
                </Select>
            </Grid>
        </Grid>
    </Box>
    )
}
