import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeProvider from './theme';
import { OnlineFormContextProvider } from './contexts/OnlineFormContextProvider';
import ThemeColorPresets from './components/ThemeColorPresets';

import Error404 from './Error404';
import InvalidInput from './InvalidInput';
import OnlineForm from './OnlineForm';
import Carousel from './Carousel';

const root = ReactDOM.createRoot(document.getElementById('workingfeedback_react_root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ThemeColorPresets>    
        <BrowserRouter>
          <Routes>
            <Route path="render/online-form/:companyID" element={<OnlineFormContextProvider><OnlineForm /></OnlineFormContextProvider>} />
            <Route path="render/carousel/:companyID" element={<Carousel />} />
            <Route path="render/invalid" element={<InvalidInput />} />
            <Route path="*" element={<Error404 />} />
          </Routes>      
        </BrowserRouter>
      </ThemeColorPresets>
    </ThemeProvider>
  </React.StrictMode>
);
