import { useContext } from 'react';
import { OnlineFormContext, OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ----------------------------------------------------------------------

export default function TreatmentType(props) {

    
    const settings = useContext(OnlineFormContext);
    const settingsUpdate = useContext(OnlineFormDispatchContext);    
    
    const handleChange = (e) => { 
        settingsUpdate({ type: 'set_treatment_type', treatmentType: e.target.value });
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    if ( typeof(props.question) === 'undefined' ) return;

    return (         
    <Box style={{ display: settings?.hideTreatmentTypeQuestion ? 'none' : 'block' }} className="workingfeedback-question-block" data-question-type="TREATMENT_TYPE" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1="T_PRIVATE" data-flag2="HIDDEN" data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">Was this treatment paid for privately, or on the NHS?</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Select size="small" sx={{ width: '100%' }} value={settings?.treatmentType} defaultValue={props.question.default_value} onChange={(e) => handleChange(e)}>
                    <MenuItem value="-">Please select</MenuItem>
                    <MenuItem value="NHS">NHS</MenuItem>
                    <MenuItem value="Private">Private</MenuItem>
                    <MenuItem value="N/A">Not applicable</MenuItem>
                </Select>
            </Grid>
        </Grid>
    </Box>
    )
}
