import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
import { SingleStar, GreenStar } from '../StarRating';
// ----------------------------------------------------------------------

export default function StarRating(props) {

    const [ ratingValue, setRatingValue ] = useState(0);
    const settingsUpdate = useContext(OnlineFormDispatchContext);

    const handleChange = (e) => { 
        setRatingValue( Number(e.target.value) );
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    if ( typeof(props.question) === 'undefined' ) return;

    return ( 
    <Box className="workingfeedback-question-block" data-question-type="STAR_RATING" data-tracked={props.question?.tracked} data-multiple-choice="No" data-flag1={props.question?.flag_1} data-flag2={props.question?.flag_2} data-flag3={props.question?.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question?.text }</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'right' }}>
                <Rating value={ratingValue} icon={<GreenStar fontSize="inherit" />} emptyIcon={<SingleStar fontSize="inherit" />} size="large" onChange={(e) => handleChange(e)} />
            </Grid>
        </Grid>
    </Box>
    )
}
