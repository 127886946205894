import Box from '@mui/material/Box';
import { default as MUIDivider } from '@mui/material/Divider';

// ----------------------------------------------------------------------

export default function Divider() {
  
  return ( 
    <Box>
        <MUIDivider />
    </Box>
  )
}
