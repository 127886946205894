import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function StaffFilter(props) {

    const [ staffValue, setStaffValue ] = useState('-');
    const settingsUpdate = useContext(OnlineFormDispatchContext);
    
    if ( typeof(props.question) === 'undefined' ) return;
    
    const handleChange = (e) => { 
        setStaffValue( e.target.value );
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    return ( 
    <Box className="workingfeedback-question-block" data-question-type="STAFF_FILTER" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1={props.question.flag_1} data-flag2={props.question.flag_2} data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text }</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Select size="small" sx={{ width: '100%' }} value={staffValue} defaultValue={props.question.default_value} onChange={(e) => handleChange(e)}>
                    <MenuItem value="-">Please select</MenuItem>
                    { props.question.answers?.staff?.map( (staff, i) =>  
                        <MenuItem key={i} value={staff.id}>{staff.name}</MenuItem>
                    ) }
                    <MenuItem value="None of the above">None of the above</MenuItem>
                    <MenuItem value="I can't remember">I can't remember</MenuItem>
                </Select>
            </Grid>
        </Grid>
    </Box>
    )
}
