import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function Error404() {

  const navigate = useNavigate();

  useEffect( () => { 
    window.location.href = 'https://www.workingfeedback.co.uk';
  }, [navigate]);
    
  return (
    <></>
  );
}

