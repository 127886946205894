import { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { OnlineFormContext, OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';

// ----------------------------------------------------------------------

export default function StaticBody(props) {
  
  const settings = useContext(OnlineFormContext);
  const settingsUpdate = useContext(OnlineFormDispatchContext);

  const handleChange = (e) => { 
    settingsUpdate({ type: 'set_review_body', reviewBody: e.target.value });
  }

  return ( 
    <Box className="workingfeedback-question-block">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text } <span className="workingfeedback-required-star">*</span></Typography>
                <TextField 
                  sx={{ width: '100%' }} 
                  multiline 
                  rows="3" 
                  id="WorkingFeedback_OnlineReviewForm_ReviewBody" 
                  className="workingfeedback-question-textfield" 
                  placeholder={ props.question.default_value } 
                  value={settings?.reviewBody}  
                  autoComplete="off" 
                  onChange={(e) => handleChange(e)}
                  />
            </Grid>
        </Grid>
    </Box>
  )
}
