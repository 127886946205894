import { useContext } from 'react';
import { OnlineFormContext, OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// ----------------------------------------------------------------------

export default function StaticContact(props) {

  const settings = useContext(OnlineFormContext);
  const settingsUpdate = useContext(OnlineFormDispatchContext);
  
  const handleNameChange = (e) => { 
    settingsUpdate({ type: 'set_author_name', authorName: e.target.value });
  }

  const handleEmailChange = (e) => { 
    settingsUpdate({ type: 'set_author_email', authorEmail: e.target.value });
  }

  const handleBusinessNameChange = (e) => { 
    settingsUpdate({ type: 'set_author_business_name', authorBusinessName: e.target.value });
  }

  const handleJobTitleChange = (e) => { 
    settingsUpdate({ type: 'set_author_job_title', authorJobTitle: e.target.value });
  }

  const handleAnonymousChange = (e) => { 
    settingsUpdate({ type: 'set_anonymous', anonymous: e.target.checked });
  }
  
  return ( 
    <Box className="workingfeedback-footer">
        <Grid container spacing={2}>          
            <Grid item xs={12} sm={6} md={settings?.company?.showBNJT ? 3 : 6}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">Your name<span className="workingfeedback-required-star">*</span></Typography>
                <TextField size="small" fullWidth placeholder="e.g John Smith" className="workingfeedback-question-textfield" value={settings?.authorName} onChange={(e) =>handleNameChange(e)} />
            </Grid>
            <Grid item xs={12} sm={6} md={settings?.company?.showBNJT ? 3 : 6}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">Your email address<span className="workingfeedback-required-star">*</span></Typography>
                <TextField size="small" fullWidth placeholder="e.g John Smith" className="workingfeedback-question-textfield" value={settings?.authorEmail} onChange={(e) => handleEmailChange(e)} />
            </Grid>
            { settings?.company?.showBNJT && ( 
            <>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="body2" className="workingfeedback-question-title" component="h6">Your job title<span className="workingfeedback-required-star">*</span></Typography>
                    <TextField size="small" fullWidth placeholder="if applicable" className="workingfeedback-question-textfield" value={settings?.authorJobTitle } onChange={(e) => handleJobTitleChange(e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="body2" className="workingfeedback-question-title" component="h6">Your company name<span className="workingfeedback-required-star">*</span></Typography>
                    <TextField size="small" fullWidth placeholder="if applicable" className="workingfeedback-question-textfield" value={settings?.authorBusinessName} onChange={(e) => handleBusinessNameChange(e)} />
                </Grid>
            </>
            )}
        </Grid>
        <Typography variant="body2" mt={2} mb={2}>Your email address is for verification purposes only, and will never be made publicly visible.</Typography>

        <FormControlLabel sx={{ width: '100%' }} control={<Checkbox checked={settings?.anonymous} onChange={(e) => handleAnonymousChange(e)} />} label="If you wish your name to remain anonymous publicly, please tick this box." />
       
    </Box>
  )
}
