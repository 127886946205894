import { useContext, useState } from 'react';
import { OnlineFormContext, OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

export default function SubmitFormSection(props) {

    const settings = useContext(OnlineFormContext);
    const settingsUpdate = useContext(OnlineFormDispatchContext);
    const [ submitting, setSubmitting ] = useState(false);

    const handleCheckbox = (e) => { 
        settingsUpdate({ type: 'set_agreed_policy', agreedPolicy: e.target.checked });
    }

    const purifyQuestionsForSubmission = () => { 
        const submission = [];
        settings?.questionSet?.forEach((question, index, arr) => { 
            if ( question.type.includes('STATIC_') || question.type.includes('DIVIDER') ) { return; }            
            if ( question.hasOwnProperty('provided_answer') )
            {
                submission.push({ 
                    type: question.type, 
                    text: question.text,
                    isTracked: question.tracked || false,
                    isMultipleChoice: (question.type === 'CROSS_SELL_SERVICES' || question.type === 'MULTIPLE_CHOICE') ? true : false, 
                    answer: (question.type === 'CROSS_SELL_SERVICES' || question.type === 'MULTIPLE_CHOICE') ? null : question.provided_answer,
                    answers: (question.type === 'CROSS_SELL_SERVICES' || question.type === 'MULTIPLE_CHOICE') ? question.provided_answer : null,
                    indicator: question.indicator,
                    flag_1: question.flag_1,
                    flag_2: question.flag_2,
                    flag_3: question.flag_3,
                });
            }
        });

        return submission;
    }

    const submitForm = () => { 
        setSubmitting(true);
        try 
        { 
            settingsUpdate({ type: 'set_error_message', errorMessage: '' });

            // Must have a valid company - although this should never happen
            if ( settings?.company?.length < 1 ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "There is a problem with your form. You will not be able to submit feedback. Please contact Working Feedback on 0800 043 2100." });
                setSubmitting(false);
                return;
            }

            // Must have a review title
            if ( settings?.reviewTitle?.length < 2 ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "Please enter a title for this review - it can be anything" });
                setSubmitting(false);
                return;
            }

            // Must have a review body
            if ( settings?.reviewBody?.length < 4 ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "Please provide a little more text in the main review body. You only need to write a sentence or two." });
                setSubmitting(false);
                return;
            }

            // Check all applicable questions have been answered and are valid
            for (const question of settings?.questionSet) {
                if ( !question.hasOwnProperty('provided_answer') && !question.type.includes('STATIC_') 
                    && !question.type.includes('DIVIDER') 
                    && !question.type.includes('CROSS_SELL_SERVICES') 
                ) { 
                    settingsUpdate({ type: 'set_error_message', errorMessage: `Please answer the question: ${question.text}` });
                    setSubmitting(false);
                    return;
                }
            }

            // Must have an author name
            if ( settings?.authorName?.length < 2 ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "Please enter your full name in the box provided. We won't publish your name if you've ticked the box to remain anonymous." });
                setSubmitting(false);
                return;
            }

            // Must have a valid email address
            if ( settings?.authorEmail?.length < 2 ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "Please supply a valid email address. We need this to verify that you are a real person." });
                setSubmitting(false);
                return;
            }

            // Must have ticked to agree to the review policy
            if ( settings?.agreedPolicy === false ) { 
                settingsUpdate({ type: 'set_error_message', errorMessage: "Please tick the box to confirm that you have read and agree to the Review Policy." });
                setSubmitting(false);
                return;
            }
            
            // Create the JSON object that we'll submit to the API
            const jsonArray = {};
            const review = {};
            review.company = settings.company.id;
            review.title = settings.reviewTitle;
            review.body = settings.reviewBody;
            review.constructive = settings.constructiveText || '';
            review.name = settings.authorName;
            review.email = settings.authorEmail;
            review.business = settings.authorBusinessName || '';
            review.jobTitle = settings.authorJobTitle || '';
            review.email = settings.authorEmail;
            review.collectionMethod = settings.medium || 'online';
            review.medium = settings.medium || 'online'; // this is from old code, surely we don't need it twice?
            review.anonymous = settings.anonymous;
            jsonArray.review = review;
            jsonArray.questions = purifyQuestionsForSubmission();
            
            axios.post(`https://api.workingfeedback.co.uk/accept-review`, JSON.stringify(jsonArray), {
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then((response) => { 
                // TODO: This is old code from API6, which we will probably refactor
                console.log( response );
                if ( response.status === 204 ) { 
                    alert('Something went wrong with this submission. Please try again.');
                    return;
                }
                if ( response.status === 206 ) { 
                    alert('One or more of the values you entered on the form were invalid. Please check the information that you have entered and then try again.');
                    return;
                }                
                settingsUpdate({ type: 'set_complete', complete: true });
            })
            .catch((error) => { 
                console.log( error );
                alert('error');
            })
            .finally(() => { 
                setSubmitting(false);
            });

        } catch (error) { 
            settingsUpdate({ type: 'set_error_message', errorMessage: error.message });
            setSubmitting(false);
            return;
        }       
    }
  
  return ( 
    <Box className="workingfeedback-footer">
        <Alert severity="warning" icon={<></>}>
            <Typography variant="body2" className="workingfeedback-title" component="div"><strong>Check your review</strong></Typography>
            <Typography variant="body2" className="workingfeedback-text"component="p" mb={1}>
                Please carefully check the information that you have entered above to ensure that it is fair and accurate.
            </Typography>
            <Box sx={{ backgroundColor: '#ffffff' }} px={1}>
            <FormControlLabel sx={{ width: '100%' }} control={<Checkbox checked={settings?.agreedPolicy} onChange={(e) => handleCheckbox(e) } />} label={
                <Typography variant="body2" className="workingfeedback-text" sx={{ color: '#212B36' }}><strong>I confirm that I have read and agree to the{" "}
                    <a className="workingfeedback-link" href="https://www.workingfeedback.co.uk/review-policy">Review Policy</a>.</strong></Typography>
            } />
            </Box>
        </Alert>
    
        { settings?.errorMessage?.length > 0 && ( 
            <Box mt={1}>
                <Alert severity="error" icon={<></>}>
                    <Typography variant="body2" className="workingfeedback-text" component="p" mb={0}>
                        <strong>Error:</strong>{' '}{ settings?.errorMessage }
                    </Typography>
                </Alert>
            </Box>
        )}

        <Box mt={2}>
            <LoadingButton loading={submitting} variant="contained" color="primaryGreen" className="workingfeedback-button" onClick={() => submitForm()}>Leave feedback</LoadingButton>
        </Box>        

    </Box>
  )
}
