import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function StaticText(props) {
  
  return ( 
    <Box className="workingfeedback-question-block">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography component="div" variant="body2" className="workingfeedback-text">{ props.question.text }</Typography>
            </Grid>
        </Grid>
    </Box>
  )
}
