import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function NPS(props) {
    
    const [ npsRating, setNPSRating ] = useState(0);
    const settingsUpdate = useContext(OnlineFormDispatchContext);

    const handleChange = (e) => { 
        setNPSRating( e.target.value );
        settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
    }

    if ( typeof(props.question) === 'undefined' ) return;

    return ( 
    <Box className="workingfeedback-question-block" data-question-type="NPS_RATING" data-tracked={props.question?.tracked} data-multiple-choice="No" data-flag1={props.question?.flag_1} data-flag2={props.question?.flag_2} data-flag3={props.question?.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6" mb={0}>{ props.question?.text }</Typography>
                <Typography mb={1} className="workingfeedback-question-smalltitle" variant="body2" component="div">Drag the slider to select a rating, where 0 is the worst and 1 is the best.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={21}>
                <Slider
                    size="large"
                    defaultValue={10}
                    value={npsRating}
                    onChange={(e) => handleChange(e)}
                    valueLabelDisplay="on"
                    step={1}
                    marks
                    min={0}
                    max={10}
                />
            </Grid>
        </Grid>
    </Box>
    )
}
