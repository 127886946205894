import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { OnlineFormContext, OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';

// ----------------------------------------------------------------------

export default function StaticImprovements(props) {

    const [ hasAdditionalComments, setHasAdditionalComments ] = useState('-');

    const handleDropChange = (e) => { 
        setHasAdditionalComments( e.target.value );
        if ( e.target.value === 'No' ) { 
            settingsUpdate({ type: 'set_constructive_comments', constructiveText: '' });
        }
    }

    const settings = useContext(OnlineFormContext);
    const settingsUpdate = useContext(OnlineFormDispatchContext);

    const handleTextChange = (e) => { 
        settingsUpdate({ type: 'set_constructive_comments', constructiveText: e.target.value });
    }
  
    return ( 
    <Box className="workingfeedback-question-block">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text?.length > 1 ? props.question.text : 'Could anything be improved?' } <span className="workingfeedback-required-star">*</span></Typography>
                <Typography mb={1} className="workingfeedback-question-smalltitle" variant="body2" component="div">{ props.question.flag_3?.length > 1 ? props.question.flag_3 : 'Could something have been done differently? Let them know in confidence.' }</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Select size="small" sx={{ width: '100%' }} value={hasAdditionalComments} onChange={handleDropChange}>
                    <MenuItem value="-">Please select</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </Select>
            </Grid>
        </Grid>

        { hasAdditionalComments === 'Yes' ? ( 
            <Grid container>        
                <Grid item xs={12}>                
                    <TextField 
                        sx={{ width: '100%' }} 
                        multiline 
                        rows="2" 
                        className="workingfeedback-question-textfield" 
                        placeholder={ props.question.default_value } 
                        value={settings?.constructiveText} 
                        autoComplete="off" 
                        onChange={(e) => handleTextChange(e)}
                    />                
                </Grid> 
            </Grid>
        ) : ( 
            <input type="hidden" id="WorkingFeedback_OnlineReviewForm_ConstructiveComments" value="" />
        )}
    </Box>
    )
}
