import { useParams } from 'react-router-dom';

export default function Carousel() {

    const params = useParams();

    return ( 
        <p>Carousel for {params.companyID}</p>
    )
    
}