import { createContext, useReducer } from 'react';

export const OnlineFormContext = createContext(null);
export const OnlineFormDispatchContext = createContext(null);

export function OnlineFormContextProvider({ children }) 
{

  const [ settings, dispatch ] = useReducer(
    onlineFormReducer,
    initialSettings
  );

  return (
    <OnlineFormContext.Provider value={settings}>
      <OnlineFormDispatchContext.Provider value={dispatch}>
        {children}
      </OnlineFormDispatchContext.Provider>
    </OnlineFormContext.Provider>
  );
} 

const initialSettings = { 
  complete: false, 
  company: {}, 
  agreedPolicy: false,
  reviewBody: '', 
  reviewTitle: '', 
  constructiveText: '', 
  anonymous: false, 
  authorName: '', 
  authorEmail: '', 
  authorBusinessName: '', 
  authorJobTitle: '', 
  questionSet: [], 
  inviteID: 0, 
  medium: 'online', 
  treatmentType: '-', 
  hideTreatmentTypeQuestion: false,
  fftEligible: false, 
  errorMessage: '',
}

function onlineFormReducer(settings, action) 
{
  
  switch( action.type )
  {
    case 'set_error_message': { 
      return { ...settings, errorMessage: action.errorMessage }
    }
    case 'set_complete': { 
      return { ...settings, complete: action.complete }
    }  
    case 'set_company': { 
      return { ...settings, company: action.company }
    }    
    case 'set_questions': { 
      return { ...settings, questionSet: action.questionSet }
    }
    case 'set_review_body': { 
      return { ...settings, reviewBody: action.reviewBody }
    }
    case 'set_review_title': { 
      return { ...settings, reviewTitle: action.reviewTitle }
    }
    case 'set_constructive_comments': { 
      return { ...settings, constructiveText: action.constructiveText }
    }
    case 'set_anonymous': { 
      return { ...settings, anonymous: action.anonymous }
    }
    case 'set_author_name': { 
      return { ...settings, authorName: action.authorName }
    }
    case 'set_author_email': { 
      return { ...settings, authorEmail: action.authorEmail }
    }
    case 'set_author_business_name': { 
      return { ...settings, authorBusinessName: action.authorBusinessName }
    }
    case 'set_author_job_title': { 
      return { ...settings, authorJobTitle: action.authorJobTitle }
    }
    case 'set_treatment_type': { 
      return { ...settings, treatmentType: action.treatmentType }
    }
    case 'set_hide_treatment_type_question': { 
      return { ...settings, hideTreatmentTypeQuestion: action.hideTreatmentTypeQuestion }
    }
    case 'set_question_answer': { 
      let updatedQuestionSet = settings.questionSet;
      updatedQuestionSet.forEach( (item, index, arr) => { 
        if (item.question_id === action.questionID) { 
          arr[index].provided_answer = action.providedAnswer;
        }
      });
      return { ...settings, questionSet: updatedQuestionSet }
    }
    case 'set_agreed_policy': { 
      return { ...settings, agreedPolicy: action.agreedPolicy }
    }   
    default:
      throw Error('Unknown action: ' + action.type);
  }
  
}
