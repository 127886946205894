import { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { OnlineFormDispatchContext } from '../../contexts/OnlineFormContextProvider';
// ----------------------------------------------------------------------

export default function Freetext(props) {
  
  const [ freetextComments, setFreetextComments ] = useState('');
  const settingsUpdate = useContext(OnlineFormDispatchContext);

  const handleChange = (e) => { 
    setFreetextComments( e.target.value );
    settingsUpdate({ type: 'set_question_answer', questionID: props.question.question_id, providedAnswer: e.target.value });
  }

  return ( 
    <Box className="workingfeedback-question-block" data-question-type="FREETEXT" data-tracked={props.question.tracked} data-multiple-choice="No" data-flag1={props.question.flag_1} data-flag2={props.question.flag_2} data-flag3={props.question.flag_3}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2" className="workingfeedback-question-title" component="h6">{ props.question.text }</Typography>
                <TextField 
                  size="small" 
                  className="workingfeedback-question-textfield" 
                  sx={{ width: '100%' }} 
                  placeholder={ props.question.default_value } 
                  value={freetextComments} 
                  defaultValue={props.invite?.description} 
                  autoComplete="off" 
                  onChange={(e) => handleChange(e)}
                  />
            </Grid>
        </Grid>
    </Box>
  )
}
